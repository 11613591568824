* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

html {
  scroll-behavior: smooth;
}

.icon {
  display: flex;
  justify-content: center; /* Mengatur posisi ikon secara horizontal */
  align-items: center; /* Mengatur posisi ikon secara vertikal (opsional, jika tinggi div lebih besar) */
  gap: 10px; /* Jarak antar ikon (opsional) */
}

.icon a,
.icon i {
  color: #000; /* Warna ikon (ganti sesuai kebutuhan) */
}

.offer-section {
  padding: 20px;
}

.offer-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.offer-img {
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
}

.offer-img img {
  max-width: 100%;
  height: auto;
}

.offer-img h2 {
  font-size: 1.5em;
  margin: 10px 0;
}

.offer-img p {
  font-size: 1em;
  line-height: 1.5;
}

.contact-info {
  font-size: 0.9em;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .offer-img {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .offer-img {
    flex: 1 1 100%;
  }
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: transparent;
  transition: background 0.5s;
  z-index: 999;
}

.scrolled {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}
.logo h1 {
  font-size: 22px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #2f363d;
  /* color: #17a2b8; */
}

li a {
  padding: 20px;
  font: 500 14px/100px "Rubik", sans-serif;
  line-height: 100px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}

a:active {
  color: #854fee;
}

a:hover {
  cursor: pointer;
  color: #854fee;
}

ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
}

.left-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  gap: 30px;
}

.right {
  width: 50%;
}

.right img {
  width: 100%;
}

h3 {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

h1 {
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}

h5 {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
}

h4 {
  height: 2px;
  width: 410px;
  left: 146.046875px;
  top: 24px;
  border-radius: 0px;
}

.hii {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line {
  width: 40%;
  border: 1px solid black;
  height: 1px;
  background-color: black;
}

.intro {
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
}

.intro .left img {
  width: 100%;
  padding-left: 0;
}

.about {
  padding: 50px 0px 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

p {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

h2 {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

button {
  height: 50px;
  width: 143.8px;
  left: 0px;
  top: 381px;
  border-radius: 5px;
  background: linear-gradient(90deg, #2a3688 0%, #854fee 100%);
  box-shadow: 0px 10px 30px rgba(118, 85, 225, 0.3);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  border: 0;
}

button:hover {
  border: 1px solid #854fee;
  cursor: pointer;
  color: black;
  background: transparent;
}

.hire button {
  background: #fff;
  color: black;
  border: 1px solid #854fee;
}

.hire button:hover {
  background: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  color: white;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.top {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.content-logo {
  display: flex;
  flex-direction: row;
}

.image a {
  width: 170px;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.sk {
  display: flex;
  justify-content: flex-end;
}

.contact {
  margin: 25px 15px 22px 0;
  background-color: #faf8ff;
  width: 350px;
  height: 342px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.year {
  display: flex;
  align-items: center;
  height: 85px;
  width: 290px;
  gap: 10px;
}

.year h1 {
  font-family: "Rubik", sans-serif;
  font-size: 100px;
  font-weight: 700;
  line-height: 85px;
  letter-spacing: 0em;
  text-align: center;
}

.year h3 {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.no {
  text-align: center;
}

.no p {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.no h2 {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-no {
  margin: 50px 0 0 50px;
}

.div-image {
  position: relative;
  left: 140px;
}
.headline {
  gap: 23px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.headline h1 {
  font-family: "Rubik", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.headline p {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.offers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  gap: 100px;
}

.offer-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.offer-img {
  height: 395px;
  width: 255px;
  left: 15px;
  top: 0px;
  border-radius: 0px;
  background-color: #f9f9fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.offer-img:hover {
  background: #fff;
  box-shadow: 0px 15px 30px rgb(77 87 222 / 30%);
}

.offer-img p {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.offer-img h2 {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.project h1 {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.update {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: -1px -3px 48px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px -3px 48px 4px rgba(0, 0, 0, 0.5);
  box-shadow: -1px -3px 48px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 483px;
  gap: 50px;
  border-radius: 10px;
}

.text {
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.text h3 {
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.text p {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
}

.icon {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.update-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.btns button {
  background: white;
  color: black;
}

.email {
  width: 350px;
  padding-left: 25px;
  height: 50px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid black;
}

.footer {
  margin-left: 10%;
  margin-right: 10%;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.footer .text h2 {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

footer p {
  text-align: center;
}

.landingpage {
  margin-top: -70px;
  background-color: transparent;
}
#portfolio {
  padding-top: 200px;
}
#services {
  margin-top: 200px;
}
.fa {
  font-size: 50px;
  opacity: 0.5;
  color: var(--gray);
}

.fa:hover {
  opacity: 1;
}

section {
  padding-top: 120px;
  background-color: #fff;
}

/* hover */
@media screen and (max-width: 766) {
  .btn button {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .btn {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  ul {
    display: none;
  }
  .forgot {
    display: none;
  }
  h1 {
    font-size: 54px;
  }
  br {
    display: none;
  }
  .content-logo {
    display: flex;
    flex-direction: column;
  }
  .content-logo .left,
  .content-logo .right {
    width: 100%;
  }
  .sk {
    display: inline-flex;
  }

  .offer-content {
    gap: 5px;
    display: flex;
    justify-content: center;
  }
  .intro .right {
    width: 100%;
  }
  .about {
    padding: 0px;
  }
  .year {
    flex-direction: column;
  }
  .sk {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div-image {
    display: none;
  }
}
@media screen and (max-width: 654px) {
  .offer-img {
    width: auto;
    height: auto;
  }
  .top {
    justify-content: center;
  }
  .div-image img {
    display: none;
  }
  .header {
    position: relative;
  }
  .update {
    padding: 20px;
  }
  .header {
    padding-left: 20px;
  }
  .content {
    margin: 0pcx;
  }
}

@media screen and (max-width: 484px) {
  .btns input {
    width: 80%;
  }
  .div-image img {
    display: none;
  }
  .wid {
    width: 100%;
  }
  .header {
    padding-left: 20px;
  }
}
